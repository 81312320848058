import { ReactComponent as Aircheck } from 'assets/icons/aircheck.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as CrossedEye } from 'assets/icons/crossedEye.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Expand } from 'assets/icons/expand.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Information } from 'assets/icons/information.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Message } from 'assets/icons/message.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { ReactComponent as Stop } from 'assets/icons/stop.svg';
import { ReactComponent as Time } from 'assets/icons/time.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Undo } from 'assets/icons/undo.svg';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Pause } from 'assets/icons/pause.svg';

const iconsMap: { [key: string]: any } = {
  aircheck: Aircheck,
  calendar: Calendar,
  copy: Copy,
  check: Check,
  crossedEye: CrossedEye,
  edit: Edit,
  expand: Expand,
  eye: Eye,
  information: Information,
  mail: Mail,
  message: Message,
  play: Play,
  plus: Plus,
  spinner: Spinner,
  stop: Stop,
  trash: Trash,
  time: Time,
  upload: Upload,
  undo: Undo,
  close: Close,
  pause: Pause,
};

export default iconsMap;
