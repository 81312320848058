/* eslint-disable no-magic-numbers */
import { BYTES_IN_KB } from 'constants/general';
import { formatDate } from 'helpers/dateHelper';
import { ICodesTable } from 'interfaces/Code/ICodesTable';

export const codeFileInfo = (code: ICodesTable) => {
  if (!code.audio_file_name) { return; }

  const date = formatDate(code.audio_file_created_at!);
  let size;
  if (code.audio_file_size! >= BYTES_IN_KB) {
    size = `${(code.audio_file_size! / BYTES_IN_KB).toFixed(2)}MB`;
  } else {
    size = `${code.audio_file_size!}KB`;
  }
  const duration = `${code.audio_file_duration}sec`;

  return `Uploaded ${date}, ${size}, dur ${duration}`;
};
