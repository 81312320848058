import { SpotType } from './enums/SpotTypes';

export const spotStatuses = {
  noStatus: 'No Status',
  success: 'Success',
  dnr: 'DNR',
  error: 'Error',
  canceled: 'Canceled',
};

export const spotTransitions = {
  cancel: 'Cancel',
  uncancel: 'Uncancel',
  markAsError: 'Mark as Error',
  ummarkAsError: 'Unmark as Error',
  markAsDnr: 'Mark as DNR',
  ummarkAsDnr: 'Unmark as DNR',
};

export const statusesForCancelAction = [
  spotStatuses.noStatus,
  spotStatuses.success,
  spotStatuses.dnr,
  spotStatuses.error,
];
export const statusesForDisabledCancel = [spotStatuses.success, spotStatuses.dnr, spotStatuses.error];

export const statusesForErrorAction = [
  spotStatuses.noStatus,
  spotStatuses.dnr,
  spotStatuses.canceled,
  spotStatuses.success,
];
export const statusesForDisabledErrorAction = [spotStatuses.canceled, spotStatuses.dnr];

export const statusesForDNRAction = [
  spotStatuses.noStatus,
  spotStatuses.success,
  spotStatuses.error,
  spotStatuses.canceled,
];
export const statusesForDisabledDNRAction = [
  spotStatuses.success,
  spotStatuses.dnr,
  spotStatuses.error,
  spotStatuses.canceled,
];

export const spotPopups: { [key: string]: string } = {
  'Confirm Spot Cancellation': 'Confirm Spot Cancellation',
};

export const spotImportRecordsStatuses = {
  inProgress: 'In progress',
  success: 'Success',
  deleting: 'Deleting',
  deleted: 'Deleted',
  error: 'Error',
};

export const trafficNoSpotsError = {
  title: 'Traffic Cannot be Sent',
  type: 'Info',
  details:
    'The Spot records you selected either have no associated Codes or have' +
    ' the incorrect status (DNR, Error, Canceled, Success), so traffic cannot be sent.',
};

export const SELECTED_SPOT_ID_KEY = 'spotId';

export const ALL_EXCEPT_TABLE_HEIGHT = 273;
export const EXPANDED_FILTER_HEIGHT = 328;
export const HIDDEN_FILTER_HEIGHT = 63;

export const SKELETON_ROWS_COUNT = 10;
export const ROW_HEIGHT = 110;

export const CHECKBOX_WIDTH = '34px';
export const cellStyles = {
  [SpotType.legacy]: {
    checkbox: { width: CHECKBOX_WIDTH },
    outlet: { minWidth: '230px', width: '20%' },
    market: { minWidth: '71px', width: '10%' },
    format: { minWidth: '70px', width: '10%' },
    code: { width: '12%', minWidth: '60px' },
    gross: { width: '4%', textAlign: 'right', paddingRight: 0, paddingLeft: 0 },
    grossAmount: { width: '9%', textAlign: 'right', paddingLeft: '5px' },
    net: { width: '4%', textAlign: 'right', paddingRight: 0, paddingLeft: 0 },
    netAmount: { width: '9%', textAlign: 'right', paddingLeft: '5px' },
    actions: { width: `calc(22% - ${CHECKBOX_WIDTH})`, minWidth: '270px', alignContent: 'right' },
  },
  [SpotType.podcast]: {
    checkbox: { width: CHECKBOX_WIDTH },
    outlet: { minWidth: '230px', width: '19%' },
    market: { minWidth: '71px', width: '8%' },
    format: { minWidth: '70px', width: '8%' },
    testShow: { width: '12%' },
    pixelRequired: { width: '10%' },
    gross: { width: '3%', textAlign: 'right', paddingRight: 0, paddingLeft: 0 },
    grossAmount: { width: '8%', textAlign: 'right', paddingLeft: '5px' },
    net: { width: '3%', textAlign: 'right', paddingRight: 0, paddingLeft: 0 },
    netAmount: { width: '8%', textAlign: 'right', paddingLeft: '5px' },
    actions: { width: `calc(21% - ${CHECKBOX_WIDTH})`, minWidth: '270px', alignContent: 'right' },
  },
};
