import { IAudioFile } from 'interfaces/Code/IAudioFile';
import { ICodeAddForm } from 'interfaces/Code/ICodeAddForm';
import { ICodeEditForm } from 'interfaces/Code/ICodeEditForm';

import { request, screenRequest } from './instance';

export const CodeAPI = {
  getOne: async (creativeId: number, codeId: number) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}`,
        method: 'GET',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getByName: async (value: string, creativeId: number | '') => {
    const response = await request({
      url: 'v1/codes/search',
      method: 'GET',
      params: { value, creative_id: creativeId },
    });

    return response.data.data;
  },

  getAll: async (creativeId: number) => {
    try {
      const response = await screenRequest({
        url: `v1/creatives/${creativeId}/codes`,
        method: 'GET',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  createCode: async (creativeId: number, codeFormData: ICodeAddForm) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes`,
        method: 'POST',
        data: { data: { code: codeFormData } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateCode: async (creativeId: number, codeId: number, codeFormData: ICodeEditForm) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}`,
        method: 'PUT',
        data: { data: { code: codeFormData } },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getFileUploadPath: async ({
    creativeId,
    codeId,
    filename,
  }: {
    creativeId: number;
    codeId: number;
    filename: string;
  }) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}/audio_urls/upload_link`,
        method: 'GET',
        params: { filename },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  addFile: async (creativeId: number, codeId: number, fileData: IAudioFile) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}/audio_files`,
        method: 'POST',
        data: {
          data: {
            audio_file: fileData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  removeFile: async (creativeId: number, codeId: number) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}/audio_files`,
        method: 'DELETE',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  switchCodeActivation: async (creativeId: number, codeId: number, active: boolean) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}/switch_activation`,
        method: 'PATCH',
        data: {
          data: {
            code: {
              is_active: active,
            },
          },
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  removeCode: async (creativeId: number, codeId: number) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}`,
        method: 'DELETE',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getFileDownloadPath: async ({ creativeId, codeId }: { creativeId: number; codeId: number }) => {
    try {
      const response = await request({
        url: `v1/creatives/${creativeId}/codes/${codeId}/audio_urls/download_link`,
        method: 'GET',
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },
};
