import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrderAPI } from 'api/orderAPI';
import { FieldArray, Form, Formik } from 'formik';

import { Checkbox, LinkButton, OutlinedButton, SubmitButton, TextArea } from 'components/UIComponents';
import {
  FieldGroupTitleContainer,
  ModalInfoBlock,
  ModalInfoBlockTitle,
  CommentTitle,
} from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { contactInfo } from 'helpers/spotsHelper';
import { nonEmptyValues } from 'helpers/utils';
import { orderValidationSchema } from 'helpers/validation/orderValidationSchema';

import { SpotType } from 'constants/enums/SpotTypes';
import RESPONSE_CODES from 'constants/responseCodes';

import { IOrderFormItem, IOrderGroups } from 'interfaces/Order/IOrderForm';
import { IAPIError, IErrorResponseData, IResponse } from 'interfaces/api';

import { ContactInfo } from './styledComponents';

interface Props {
  type: SpotType;
  onClose: (error?: IAPIError) => void;
  data: IOrderGroups;
}

const OrderForm = ({ type, onClose, data }: Props) => {
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(Object.values(data).every((infoGroup) => !infoGroup.contact_email));

  const initialValues = {
    orders: Object.keys(data).map((groupName) => ({
      ...data[groupName],
      name: groupName,
      comment: '',
    })),
  };

  const onApplyCommentToAll = (
    values: { orders: IOrderFormItem[] },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    const comment = values.orders[0].comment;
    const orders = values.orders.map((item) => ({ ...item, comment }));
    setFieldValue('orders', orders);
  };

  const onFormSubmit = (values: any, helpers: any, send?: boolean) => {
    setIsDisabled(true);

    const orders = values.orders.map((item: IOrderFormItem) =>
      nonEmptyValues({
        group_id: item.group_id,
        comment: item.comment,
        spots_ids: item.spots_ids,
        is_revised: item.is_revised,
        type: type,
      })
    );

    OrderAPI.create(orders, send).then((response: IResponse) => {
      setIsDisabled(false);
      if (response.status !== RESPONSE_CODES.CREATED) {
        onClose((response.data as IErrorResponseData).error);
      } else {
        onClose();
        navigate('/orders');
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={orderValidationSchema}
        enableReinitialize={true}
        validateOnBlur
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <Form>
            <DialogContent>
              <FieldArray
                name="orders"
                render={() => {
                  return (
                    <>
                      {values.orders.map((orderInfo, index) => {
                        const showApplyToAllButton = index === 0 && values.orders.length !== 1;
                        return (
                          <ModalInfoBlock key={index}>
                            <ModalInfoBlockTitle>{orderInfo.name}</ModalInfoBlockTitle>
                            <FieldGroupTitleContainer>
                              CONTACT
                              <ContactInfo>
                                {orderInfo.contact_email
                                  ? contactInfo(orderInfo.contact_name, orderInfo.contact_email)
                                  : 'No active default contact'}
                              </ContactInfo>
                            </FieldGroupTitleContainer>
                            <CommentTitle>COMMENT</CommentTitle>
                            <TextArea name={`orders[${index}].comment`} />
                            {showApplyToAllButton && (
                              <LinkButton
                                disabled={!values.orders[0].comment}
                                onClick={() => onApplyCommentToAll(values, setFieldValue)}
                              >
                                Apply comment to all orders
                              </LinkButton>
                            )}
                            <Checkbox
                              name={`orders[${index}].is_revised`}
                              label="Revised"
                              view="checkboxFirst"
                              styles={{
                                wrapper: {
                                  marginTop: showApplyToAllButton ? '0px !important' : '-18px !important',
                                  marginBottom: '0px !important',
                                },
                              }}
                            />
                          </ModalInfoBlock>
                        );
                      })}
                    </>
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={!!Object.values(errors).length || isDisabled}
                onClick={() => onFormSubmit(values, {}, true)}
              >
                Send
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrderForm;
