import { CodeAPI } from 'api/codeAPI';
import uploadFileToCloud from 'services/uploadFileToCloud';
import saveCodeFileInfo from 'services/saveCodeFileInfo';
import { ICodesTable } from 'interfaces/Code/ICodesTable';
import RESPONSE_CODES from 'constants/responseCodes';

const processCodeFile = (file: File, code: ICodesTable) => {
  return CodeAPI.getFileUploadPath({
    creativeId: code.creative_id,
    codeId: code.id,
    filename: file.name }
    ).then((response) => {
      if (response.status === RESPONSE_CODES.OK) {
        return uploadFileToCloud(file, response.data.data.url);
      } else {
        throw new Error(response.data.error?.details);
      }
    }).then((result) => (
      saveCodeFileInfo(file, code.creative_id, code.id)
    ));
};

export default processCodeFile;
