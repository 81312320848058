import { useCallback, useState } from 'react';

import { ResponseAPI } from 'api/responseAPI';

import { OverflowTip } from 'components/UIComponents';
import InlineEdit from 'components/base/InlineEdit';
import ListOfRecords from 'components/base/ListOfRecords';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CheckboxCell from 'components/base/tableComponents/CheckboxCell';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectState } from 'context/BulkSelectContext';
import { FilterContextState } from 'context/FilterContext';
import { RefreshContextProvider } from 'context/RefreshContext';

import { getUTCDate } from 'helpers/dateHelper';
import { prepareFilterData } from 'helpers/responses';

import { SpotType } from 'constants/enums/SpotTypes';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ITableColumn } from 'interfaces/ITableColumn';
import { IResponsesTable } from 'interfaces/Response/IResponsesTable';

import ActionsBar from './ActionsBar';
import BulkActions from './BulkActions';
import DeleteAction from './DeleteAction';
import EditAction from './EditAction';
import EditableCell from './EditableCell';
import ResponseStatus from './ResponseStatus';
import { columns } from './columnsMap';
import { Actions } from './styledComponents';

const ListOfResponses = () => {
  const [responsesProvider, setResponsesProvider] = useState(
    () => (props: IFilterParams) => ResponseAPI.getList(props)
  );

  const reloadList = useCallback(() => {
    setResponsesProvider(() => (props: IFilterParams) => ResponseAPI.getList(props));
  }, []);

  const dataFormatter = (item: IResponsesTable) => ({
    id: item.id,
    checkbox: <CheckboxCell id={item.id} />,
    code: item.spot_id ? (
      <OverflowTip title={item.code}>{item.code}</OverflowTip>
    ) : (
      <EditableCell item={item} valueKey="code" />
    ),
    date: item.spot_id ? (
      item.date && <OverflowTip title={String(getUTCDate(item.date))}>{String(getUTCDate(item.date))}</OverflowTip>
    ) : (
      <InlineEdit
        initialValue={item.date}
        update={(value: string) => ResponseAPI.update(item.id, { date: value })}
        type="date"
        displayView={(value: string) => {
          if (value) {
            const date = String(getUTCDate(value));
            return <OverflowTip title={date}>{date}</OverflowTip>;
          }

          return value;
        }}
      />
    ),
    zipCode: item.spot_id ? (
      <OverflowTip title={item.zip}>{item.zip}</OverflowTip>
    ) : (
      <EditableCell item={item} valueKey="zip" />
    ),
    phone: item.spot_id ? (
      <OverflowTip title={item.phone}>{item.phone}</OverflowTip>
    ) : (
      <EditableCell item={item} valueKey="phone" />
    ),
    source: <EditableCell item={item} valueKey="source" />,
    fileName: <OverflowTip title={item.file_name}>{item.file_name}</OverflowTip>,
    status: (
      <ResponseStatus
        spotId={item.spot_id}
        spotType={item.spot_type === 'LegacySpot' ? SpotType.legacy : SpotType.podcast}
        outlet={item.outlet_name}
        startDate={item.spot_start_date}
        endDate={item.spot_end_date}
      />
    ),
    actions: (
      <Actions>
        <EditAction responseId={item.id} />
        <DeleteAction responseId={item.id} />
      </Actions>
    ),
  });

  return (
    <PageContainer>
      <PageTitle>Responses</PageTitle>

      <BaseListActionsContext.Provider value={{ reloadList }}>
        <RefreshContextProvider callback={reloadList}>
          <FilterContextState dataSerializer={(filters: object) => prepareFilterData(filters)}>
            <BulkSelectState actions={<BulkActions />}>
              <ListOfRecords
                tableProps={{
                  name: 'responses',
                  columns: columns as ITableColumn[],
                  rowsAsLinks: false,
                  withActions: false,
                  tableStyle: { display: 'table', tableLayout: 'fixed' },
                }}
                barActions={<></>}
                dataFormatter={dataFormatter}
                dataProvider={responsesProvider}
                filter={<ActionsBar />}
                extraPageElementsHeight={45}
                skipSearch
              />
            </BulkSelectState>
          </FilterContextState>
        </RefreshContextProvider>
      </BaseListActionsContext.Provider>
    </PageContainer>
  );
};

export default ListOfResponses;
