import { OverflowTip } from 'components/UIComponents';

import { cellStyles } from 'constants/spots';

import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';

import Cell from '../Cell';

interface Props {
  spot: ILegacySpotsTable;
}

const CodeInfoCell = ({ spot }: Props) => {
  return (
    <Cell
      cellProps={{ style: cellStyles.Legacy.code }}
      content={
        <>
          {spot.isci ? <OverflowTip title={spot.isci}>{spot.isci}</OverflowTip> : <div />}
          {spot.code ? <OverflowTip title={spot.code}>{spot.code}</OverflowTip> : ''}
        </>
      }
    />
  );
};

export default CodeInfoCell;
