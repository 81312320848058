import { useState } from 'react';

import { IconButton, CircularProgress } from '@mui/material';

import getCodeFile from 'services/getCodeFile';

import { CustomIcon } from 'components/UIComponents';

import { useAudioContext } from 'context/MP3Context';

import { ICodesTable } from 'interfaces/Code/ICodesTable';

import { CodeAPI } from 'api/codeAPI';

import { LoadingBlock, StyledContainer } from './styledComponents';

interface MP3PlayBlockProps {
  code: ICodesTable;
  onSuccess: () => void;
}

const MP3PlayBlock = ({ code, onSuccess }: MP3PlayBlockProps) => {
  const [audioURI, setAudioURI] = useState('');
  const {
    playAudio,
    pauseAudio,
    isPlaying,
    isLoading,
    setIsLoading,
    setSelectedAudioId,
    selectedAudioId,
  } = useAudioContext();

  const onStopClick = () => {
    pauseAudio();
  };

  const onPlayClick = () => {
    setSelectedAudioId(code.id);
    setIsLoading(true);
    onStopClick();
    if (!audioURI) {
      getCodeFile(code)
        .then((data) => {
          playAudio(data.url);
          setAudioURI(data.url);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      playAudio(audioURI);
    }
  };

  const onDeleteClick = () => {
    if (code.id === selectedAudioId) onStopClick();
    CodeAPI.removeFile(code.creative_id, code.id).then(() => {
      onSuccess();
    });
  };

  return (
    <StyledContainer>
      {isLoading && code.id === selectedAudioId ? (
        <LoadingBlock>
          <CircularProgress color="inherit" size={16} />
        </LoadingBlock>
      ) : (
        <>
          {isPlaying && code.id === selectedAudioId ? (
            <IconButton onClick={onStopClick}>
              <CustomIcon size="normal" name="stop" />
            </IconButton>
          ) : (
            <IconButton onClick={onPlayClick}>
              <CustomIcon size="normal" name="play" />
            </IconButton>
          )}
        </>
      )}

      <IconButton onClick={onDeleteClick}>
        <CustomIcon size="normal" name="trash" />
      </IconButton>
    </StyledContainer>
  );
};

export default MP3PlayBlock;
