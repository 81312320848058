import { useContext, useEffect, useState } from 'react';

import { OrderAPI } from 'api/orderAPI';
import { IErrorResponseData, IResponse } from 'interfaces/api';

import CommentModalForm from 'components/forms/CommentModalForm';
import { LinkButton } from 'components/UIComponents';

import { BaseListActionsContext } from 'context/BaseListActions';
import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { ModalContext } from 'context/ModalContext';
import { ToastContext } from 'context/ToastContext';
import { usePopup } from 'context/GlobalPopupContext';

import { PopupType, ToastType } from 'constants/enums/PopupType';
import RESPONSE_CODES from 'constants/responseCodes';

import { apiErrorToPopupData } from 'helpers/utils';


const SendAction = () => {
  const { openPopup } = usePopup();

  const { onModalOpen } = useContext(ModalContext);
  const { selectAll, excludedIds, ids, totalCount } = useContext(BulkSelectContext);
  const { onToastOpen } = useContext(ToastContext);
  const { reloadList } = useContext(BaseListActionsContext);
  const { getSerializedData } = useContext(FilterContext);

  const [storedComment, setStoredComment] = useState<string | undefined>(undefined);

  const openConfirmPopup = () => {
    const selectedCount = selectAll ? totalCount - excludedIds.length : ids.length;
    const plularizedOrders = selectedCount === 1 ? 'Order' : 'Orders';
    openPopup({
      title: 'Confirm Order Revision',
      type: PopupType.warning,
      content: `You are about to revise ${selectedCount} ${plularizedOrders}.
        As the result, the ${plularizedOrders} will be sent to Groups and marked as Revised.
        That will also reset current ${plularizedOrders} statuses. Continue?`,
      cancelText: 'No, cancel',
      submitText: 'Yes, continue',
      submitCallback: () => onModalOpen('COMMENT_MODAL_FORM_SEND_ORDERS'),
    });
  };

  const forceProceed = () => {
    handleRequest(true, storedComment as string);
  };

  const handleSend = (comment: string | undefined) => {
    setStoredComment(comment);
  };

  useEffect(() => {
    if (storedComment !== undefined) {
      handleRequest(false, storedComment);
    }
  }, [storedComment]);

  const handleRequest = (force: boolean = false, comment: string) => {
    OrderAPI.sendOrders({ selectAll, ids, excludedIds }, getSerializedData(), force, comment)
    .then(handleResponse);
  };

  const handleResponse = (response: IResponse) => {
    if (response.status === RESPONSE_CODES.OK) {
      reloadList();
      onToastOpen('ORDER_BULK_ACTION_SUCCESS', response.data.data, ToastType.success);
      setStoredComment(undefined);
    } else {
      let popupData = apiErrorToPopupData((response.data as IErrorResponseData).error);
      openPopup({
        ...popupData,
        submitCallback: forceProceed,
      });
    }
  };

  return (
    <>
      <LinkButton onClick={openConfirmPopup}>Send</LinkButton>
      <CommentModalForm onSubmit={handleSend} modalName='COMMENT_MODAL_FORM_SEND_ORDERS' isMandatory/>
    </>
  );
};

export default SendAction;
