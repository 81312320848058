import { ActiveStatusLabel } from 'components/UIComponents';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';

import { ICodesTable } from 'interfaces/Code/ICodesTable';
import { ITableColumn } from 'interfaces/ITableColumn';

const columns = [
  { dataField: 'code', text: 'Code' },
  { dataField: 'keyword', text: 'Keyword' },
  { dataField: 'isci', text: 'ISCI' },
  { dataField: 'status', text: 'Status' },
];

interface Props {
  editAction: (id: number) => void;
  codesData: ICodesTable[];
  switchActivation: (codeId: number, active: boolean) => void;
  deleteAction: (codeId: number) => void;
}

const ALL_EXCEPT_TABLE_HEIGHT = 484;
const tableContainerStyles = {
  maxHeight: window.innerHeight - ALL_EXCEPT_TABLE_HEIGHT,
};

const ListOfLiveCreativeCodes = ({ editAction, codesData, switchActivation, deleteAction }: Props) => {
  const preparedData = codesData.map((item: ICodesTable) => ({
    id: item.id,
    code: item.code,
    keyword: item.keyword,
    isci: item.isci,
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [
      {
        name: item.is_active ? 'Deactivate' : 'Activate',
        action: () => switchActivation(item.id, !item.is_active),
      },
      { name: 'Delete', action: deleteAction },
    ].filter(Boolean),
  }));

  return (
    <>
      <TableWithLazyLoad
        name="codes"
        columns={columns as ITableColumn[]}
        data={preparedData}
        editAction={editAction}
        rowsAsLinks={false}
        tableContainerStyles={tableContainerStyles}
      />
    </>
  );
};

export default ListOfLiveCreativeCodes;
