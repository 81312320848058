import { useContext, useState } from 'react';

import { CircularProgress, IconButton } from '@mui/material';

import processCodeFile from 'services/processCodeFile';

import { CustomIcon, FileUploadControl } from 'components/UIComponents';
import Popup from 'components/base/Popup';

import { ModalContext } from 'context/ModalContext';

import { PopupType } from 'constants/enums/PopupType';

import { ICodesTable } from 'interfaces/Code/ICodesTable';

import { LoaderBox } from './styledComponents';

const MAX_FILE_SIZE = 50;

interface Props {
  code: ICodesTable;
  onSuccess: () => void;
}

const CodeMP3FileForm = ({ code, onSuccess }: Props) => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const [error, setError] = useState<{ title: string; content: string }>({
    title: '',
    content: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const errorModelName = `fileUploadError-${code.id}`;

  const onProcessFile = (file: File) => {
    setLoading(true);
    processCodeFile(file, code)
      .then((result) => {
        onSuccess();
      })
      .catch((error) => {
        const message = error.message;
        if (message.match(/already exists/g)) {
          setError({
            title: 'MP3 Cannot be Uploaded',
            content: 'An MP3 for this Code already exists',
          });

          onModalOpen(errorModelName);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <LoaderBox data-testid="loaderIdentificator">
          <CircularProgress size={16} color="inherit" />
        </LoaderBox>
      ) : (
        <FileUploadControl
          inputProps={{
            accept: '.mp3',
            'data-testid': `file-input${code.id}`,
          }}
          maxFileSize={MAX_FILE_SIZE}
          ActionButton={({ onClick }: { onClick: () => void }) => (
            <IconButton onClick={onClick}>
              <CustomIcon size="normal" name="upload" />
            </IconButton>
          )}
          onProcessFile={onProcessFile}
        />
      )}
      {
        <Popup
          name={errorModelName}
          type={PopupType.error}
          title={error.title}
          onModalClose={() => onModalClose(errorModelName)}
        >
          {error.content}
        </Popup>
      }
    </>
  );
};

export default CodeMP3FileForm;
