import { FormEvent, useEffect, useState } from 'react';

import { CodeAPI } from 'api/codeAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, SubmitButton, Input } from 'components/UIComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { validationEditCode } from 'helpers/validation/codeValidationSchema';

import { ICodeEditForm } from 'interfaces/Code/ICodeEditForm';

interface EditCodeFormProps {
  creativeId: number;
  onModalClose: () => void;
  successAction: () => void;
  editCodeId: number;
}

const EditCodeForm = ({ creativeId, onModalClose, successAction, editCodeId }: EditCodeFormProps) => {
  const [codeFormInformation, setCodeFormInformation] = useState<ICodeEditForm>({
    code: '',
    isci: '',
    keyword: '',
  });

  useEffect(() => {
    if (editCodeId) {
      CodeAPI.getOne(creativeId, editCodeId).then(({ data }) => {
        setCodeFormInformation({
          code: data.code,
          isci: data.isci,
          keyword: data.keyword || '',
        });
      });
    }
  }, [editCodeId, creativeId]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  return (
    <>
      <Formik
        initialValues={codeFormInformation}
        enableReinitialize={true}
        validationSchema={validationEditCode}
        validateOnBlur
        onSubmit={(values) => {
          setIsDisabled(true);
          CodeAPI.updateCode(creativeId, editCodeId, values).then(() => {
            onModalClose();
            successAction();
            setIsDisabled(false);
          });
        }}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form>
            <DialogContent>
              <Input name="code" label="Code" required />
              <Input name="isci" label="ISCI" required />
              <Input name="keyword" label="Keyword" />
            </DialogContent>
            <DialogActions>
              <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
              <SubmitButton
                disabled={isDisabled || !dirty || !!Object.values(errors).length}
                onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
              >
                Save
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditCodeForm;
