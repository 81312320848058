import { Tooltip } from '@mui/material';

import { ActiveStatusLabel } from 'components/UIComponents';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';
import CodeMP3FileForm from 'components/forms/CodeMP3FileForm';

import { MP3State } from 'context/MP3Context';

import { codeFileInfo } from 'helpers/codesHelper';

import { ICodesTable } from 'interfaces/Code/ICodesTable';
import { ITableColumn } from 'interfaces/ITableColumn';

import MP3PlayBlock from '../MP3PlayBlock';

const columns = [
  { dataField: 'code', text: 'Code' },
  { dataField: 'keyword', text: 'Keyword' },
  { dataField: 'isci', text: 'ISCI' },
  { dataField: 'filename', text: 'File name' },
  { dataField: 'mp3Actions', text: '' },
  { dataField: 'status', text: 'Status' },
];

interface Props {
  editAction: (id: number) => void;
  codesData: ICodesTable[];
  switchActivation: (codeId: number, active: boolean) => void;
  deleteAction: (codeId: number) => void;
  reloadData: () => void;
}

const ALL_EXCEPT_TABLE_HEIGHT = 484;
const tableContainerStyles = {
  maxHeight: window.innerHeight - ALL_EXCEPT_TABLE_HEIGHT,
};

const ListOfSpotCreativeCodes = ({ editAction, codesData, switchActivation, deleteAction, reloadData }: Props) => {
  const preparedData = codesData.map((item: ICodesTable) => ({
    id: item.id,
    code: item.code,
    keyword: item.keyword,
    isci: item.isci,
    filename: item.audio_file_name ? (
      <Tooltip placement="top" arrow title={codeFileInfo(item)}>
        <span>{item.audio_file_name}</span>
      </Tooltip>
    ) : (
      ''
    ),
    mp3Actions: item.audio_file_name ? (
      <MP3PlayBlock code={item} onSuccess={reloadData} />
    ) : (
      <CodeMP3FileForm code={item} onSuccess={reloadData} />
    ),
    status: <ActiveStatusLabel active={item.is_active} />,
    actions: [
      {
        name: item.is_active ? 'Deactivate' : 'Activate',
        action: () => switchActivation(item.id, !item.is_active),
      },
      { name: 'Delete', action: deleteAction },
    ].filter(Boolean),
  }));

  return (
    <>
      <MP3State>
        <TableWithLazyLoad
          name="codes"
          columns={columns as ITableColumn[]}
          data={preparedData}
          editAction={editAction}
          rowsAsLinks={false}
          tableContainerStyles={tableContainerStyles}
        />
      </MP3State>
    </>
  );
};

export default ListOfSpotCreativeCodes;
