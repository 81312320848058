import { CodeAPI } from 'api/codeAPI';
import downloadFileFromCloud from 'services/downloadFileFromCloud';
import { ICodesTable } from 'interfaces/Code/ICodesTable';
import RESPONSE_CODES from 'constants/responseCodes';

const getCodeFile = (code: ICodesTable) => {
  return CodeAPI.getFileDownloadPath({
    creativeId: code.creative_id,
    codeId: code.id,
  }).then((response) => {
    if (response.status === RESPONSE_CODES.OK) {
      return downloadFileFromCloud(response.data.data.url);
    } else {
      throw new Error(response.data.error?.details);
    }
  });
};

export default getCodeFile;
