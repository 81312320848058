import { spotStatuses } from './spots';

const statusOptions = Object.values(spotStatuses).map((status: string) => ({
  key: status,
  value: status,
}));

const legacyAircheckStatusOptions = [
  { key: 'To Complete', value: 'To Complete' },
  { key: 'Complete', value: 'Complete' },
];

const podcastAircheckStatusOptions = [
  { key: 'To Complete', value: 'To Complete' },
  { key: 'Complete (Not flagged)', value: 'Complete (Not flagged)' },
  { key: 'Complete (Flagged)', value: 'Complete (Flagged)' },
];

const paymentStatusOptions = [
  { key: 'Paid', value: 'true' },
  { key: 'Unpaid', value: 'false' },
];

const trafficStatusOptions = [
  { key: 'Traffic Sent', value: 'true' },
  { key: 'Traffic not Sent', value: 'false' },
];

const orderStatusOptions = [
  { key: 'Ordered', value: 'true' },
  { key: 'Not Ordered', value: 'false' },
];

export {
  statusOptions,
  legacyAircheckStatusOptions,
  podcastAircheckStatusOptions,
  paymentStatusOptions,
  trafficStatusOptions,
  orderStatusOptions,
};
