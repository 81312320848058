import SelectAllCheckbox from 'components/base/tableComponents/SelectAllCheckbox';

export const cellStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const columns = [
  {
    dataField: 'checkbox',
    text: <SelectAllCheckbox />,
    headStyle: { width: '2%' },
  },
  {
    dataField: 'week_start',
    text: 'Week',
    style: cellStyles,
    headStyle: { width: '8%' },
  },
  {
    dataField: 'product_name',
    text: 'Product',
    style: cellStyles,
    headStyle: { width: '16%' },
  },
  {
    dataField: 'outlet_name',
    text: 'Outlet',
    style: cellStyles,
    headStyle: { width: '16%' },
  },
  {
    dataField: 'daypart',
    text: 'Daypart',
    style: cellStyles,
    headStyle: { width: '16%' },
  },
  {
    dataField: 'total_spots_number',
    text: 'Spots',
    style: cellStyles,
    headStyle: { width: '6%' },
  },
  {
    dataField: 'total_gross',
    text: 'Spend',
    style: cellStyles,
    headStyle: { width: '6%' },
  },
  {
    dataField: 'revised',
    text: '',
    headStyle: { width: '4%' },
  },
  {
    dataField: 'actions',
    text: '',
    headStyle: { width: '26%' },
  },
];
