import { FormEvent, useCallback, useContext, useState } from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import { Form, Formik } from 'formik';

import Results from 'containers/spots/ListOfSpots/Filter/Results';

import {
  DatePickerGroup,
  Input,
  LinkButton,
  SearchableMultiSelect,
  Select,
  SelectWithSearch,
  SubmitButton,
  ButtonsBlock,
  Checkbox,
} from 'components/UIComponents';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { SpotTypeContext } from 'context/SpotTypeContext';

import {
  getCreativeOptions,
  getFormatOptions,
  getGroupOptions,
  getMarketOptions,
  getOutletOptions,
  getProductOptions,
  getUserOptions,
} from 'helpers/collections';
import { isPodcastSpot } from 'helpers/spotsHelper';
import { nonEmptyValues } from 'helpers/utils';

import { legacyAircheckStatusOptions, orderStatusOptions, podcastAircheckStatusOptions } from 'constants/spotOptions';
import { paymentStatusOptions, statusOptions, trafficStatusOptions } from 'constants/spotOptions';

import { ISelectOption } from 'interfaces/ISelectOption';
import { IFilterOptions } from 'interfaces/spots/IFilterOptions';

import { FooterGrid, controllWrapperStyles } from './styledComponents';

interface LegacyFilterFormProps {
  total?: number;
}

const emptyValues = {
  groupId: '',
  outletId: [],
  formatId: [],
  market: [],
  productId: [],
  status: [],
  podcastAircheckStatus: [],
  legacyAircheckStatus: '',
  code: '',
  isPaid: '',
  createdAtFrom: '',
  createdAtTo: '',
  isTrafficSent: '',
  buyerId: [],
  startDateFrom: '',
  startDateTo: '',
  creativeId: [],
  isOrdered: '',
  isFree: false,
};

const trafficSelect = (
  <Select
    name="isTrafficSent"
    label="Traffic"
    placeholder="Any Traffic Status"
    emptyOptionLabel="Any Traffic Status"
    options={trafficStatusOptions}
    styles={controllWrapperStyles}
    direction="column"
  />
);

const FilterForm = ({ total }: LegacyFilterFormProps) => {
  const type = useContext(SpotTypeContext);
  const { filterOptions, onFilter } = useContext(FilterContext);
  const { onChangeSelectAll } = useContext(BulkSelectContext);

  const initital = filterOptions as IFilterOptions;

  const initialValues = {
    groupId: initital.groupId?.value || '',
    outletId: initital.outletId || [],
    formatId: initital.formatId || [],
    market: initital.market || [],
    productId: initital.productId || [],
    startDateFrom: initital.startDateFrom || '',
    startDateTo: initital.startDateTo || '',
    status: initital.status || [],
    podcastAircheckStatus: initital.podcastAircheckStatus || [],
    legacyAircheckStatus: initital.legacyAircheckStatus || '',
    code: initital.code || '',
    isPaid: initital.isPaid || '',
    createdAtFrom: initital.createdAtFrom || '',
    createdAtTo: initital.createdAtTo || '',
    isTrafficSent: initital.isTrafficSent || '',
    buyerId: initital.buyerId || [],
    creativeId: initital.creativeId || [],
    isOrdered: initital.isOrdered || '',
    isFree: initital.isFree || false,
  };

  const [groupOption, setGroupOption] = useState(initital.groupId);

  const onGroupChange = (event: any, value: ISelectOption) => {
    setGroupOption(value);
  };

  const outletOptionsProvider = useCallback((value: any) => getOutletOptions(value, type), [type]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        onChangeSelectAll(false);
        const present = nonEmptyValues(values);
        if (present.groupId) {
          present.groupId = groupOption as any;
        }
        onFilter(present);
      }}
    >
      {({ values, handleSubmit, resetForm }) => {
        return (
          <Form>
            <Grid container columns={16} rowSpacing={2} columnSpacing={{ xs: 2, lg: 3 }}>
              <Grid xs={4}>
                <SelectWithSearch
                  name="groupId"
                  label="Group"
                  getOptionsList={getGroupOptions}
                  initial={{
                    key: String(groupOption?.key || ''),
                    value: String(initialValues.groupId),
                  }}
                  onChange={onGroupChange}
                  styles={controllWrapperStyles}
                  placeholder="Any Group"
                  emptyOptionLabel="Any Group"
                  direction="column"
                />
              </Grid>

              <Grid xs={4}>
                <SearchableMultiSelect
                  name="outletId"
                  label="Outlet"
                  initial={initialValues.outletId}
                  getOptionsList={outletOptionsProvider}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={4}>
                <SearchableMultiSelect
                  name="formatId"
                  label="Format"
                  getOptionsList={getFormatOptions}
                  initial={initialValues.formatId}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={4}>
                {isPodcastSpot(type) ? (
                  trafficSelect
                ) : (
                  <SearchableMultiSelect
                    name="market"
                    label="Market"
                    getOptionsList={getMarketOptions}
                    initial={initialValues.market}
                    styles={controllWrapperStyles}
                    direction="column"
                  />
                )}
              </Grid>
              <Grid xs={4}>
                <SearchableMultiSelect
                  name="productId"
                  label="Product"
                  getOptionsList={getProductOptions}
                  initial={initialValues.productId}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={4}>
                <DatePickerGroup
                  startProps={{
                    name: 'startDateFrom',
                    direction: 'column',
                    maxDate: values.startDateTo,
                  }}
                  endProps={{
                    name: 'startDateTo',
                    direction: 'column',
                    minDate: values.startDateFrom,
                  }}
                  label='Start Date'
                  connector='to'
                  styles={controllWrapperStyles}
                  direction='column'
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="status"
                  label="Status"
                  initial={initialValues.status}
                  getOptionsList={() => Promise.resolve(statusOptions)}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                {isPodcastSpot(type) ? (
                  <SearchableMultiSelect
                    name="podcastAircheckStatus"
                    label="Aircheck"
                    initial={initialValues.podcastAircheckStatus}
                    getOptionsList={() => Promise.resolve(podcastAircheckStatusOptions)}
                    styles={controllWrapperStyles}
                    direction="column"
                    emptyOptionLabel="Any Aircheck Status"
                  />
                ) : (
                  <Select
                    name="legacyAircheckStatus"
                    label="Aircheck"
                    placeholder="Any Aircheck Status"
                    emptyOptionLabel="Any Aircheck Status"
                    options={legacyAircheckStatusOptions}
                    styles={controllWrapperStyles}
                    direction="column"
                  />
                )}
              </Grid>
              <Grid xs={2}>
                <Input
                  name="code"
                  label="Code"
                  placeholder="Any Code"
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <Select
                  name="isPaid"
                  label="Payment"
                  placeholder="Any Payment Status"
                  emptyOptionLabel="Any Payment Status"
                  options={paymentStatusOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={4}>
                <DatePickerGroup
                  startProps={{
                    name: 'createdAtFrom',
                    direction: 'column',
                    maxDate: values.createdAtTo,
                  }}
                  endProps={{
                    name: 'createdAtTo',
                    direction: 'column',
                    minDate: values.createdAtFrom,
                  }}
                  label="Creation Date"
                  connector="to"
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>

              <Grid xs={4}>
                <SearchableMultiSelect
                  name="buyerId"
                  label="Buyer"
                  initial={initialValues.buyerId}
                  getOptionsList={getUserOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>

              {isPodcastSpot(type) ? '' : <Grid xs={2}>{trafficSelect}</Grid>}

              {isPodcastSpot(type) ? (
                ''
              ) : (
                <Grid xs={2}>
                  <SearchableMultiSelect
                    name="creativeId"
                    label="Creative"
                    initial={initialValues.creativeId}
                    getOptionsList={(value: any) =>  getCreativeOptions(value, '', true)}
                    styles={controllWrapperStyles}
                    direction="column"
                  />
                </Grid>
              )}

              <Grid xs={2}>
                <Select
                  name="isOrdered"
                  label="Order"
                  placeholder="Any Order Status"
                  emptyOptionLabel="Any Order Status"
                  options={orderStatusOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>

              <Grid xs={2}>
                <Checkbox
                  styles={{
                    wrapper: {
                      margin: '0 !important',
                      marginTop: '18px !important',
                    },
                  }}
                  view="checkboxFirst"
                  name="isFree"
                  label="Show $0 Spots Only"
                />
              </Grid>
              <FooterGrid xs={16}>
                <Results value={total} />
                <ButtonsBlock>
                  <LinkButton
                    onClick={() => {
                      resetForm({ values: emptyValues });
                      onChangeSelectAll(false);
                      onFilter({});
                    }}
                  >
                    Clear filter
                  </LinkButton>
                  <SubmitButton onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}>
                    Apply Filter
                  </SubmitButton>
                </ButtonsBlock>
              </FooterGrid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FilterForm;
